import { ISpreadFilterResult } from '@types';
import { Icon } from 'Icon';
import ReactTooltip from 'react-tooltip';
import {
	Sparklines,
	SparklinesLine,
	SparklinesNormalBand,
	SparklinesReferenceLine,
	SparklinesSpots,
} from 'react-sparklines';

export interface SpreadCellProps {
	row: ISpreadFilterResult;
	showType: string;
}

const SpreadCell = ({ row, showType }: SpreadCellProps) => {
	return (
		<td
			className={
				row.spread > row.spreadPrev
					? `bg-short-pulse py-3 px-1 md:px-3 border-x border-gray-200 dark:border-gray-800 text-center`
					: `bg-long-pulse py-3 px-1 md:px-3 border-x border-gray-200 dark:border-gray-800 text-center`
			}
		>
			<div className="flex items-center justify-center ">
				<div>
					{showType == 'spread'
						? `${row.spread}%`
						: showType == 'spreadAvg' && row.spreadAvg !== null
						? `${row.spreadAvg}%`
						: showType == 'ask' && row.ask
						? `${row.ask}`
						: showType == 'bid' && row.bid
						? `${row.bid}` && row.fee
						: row.fee
						? `${row.fee}%`
						: '-'}
				</div>
				<div
					data-tip
					data-for={row.brokerId + row.marketId.toString()}
					className="ml-2 h-4 w-4 text-purple-400 group-hover:text-off-white dark:text-purple-500 relative"
				>
					<Icon icon="INFO" />
				</div>
				<ReactTooltip
					className="  z-50   p-2 bg-white text-gray-300 text-sm dark:bg-gray-900 w-60 rounded-lg "
					id={row.brokerId + row.marketId.toString()}
					aria-haspopup="true"
				>
					<div>
						<table className="table-auto w-full">
							<tbody>
								<tr>
									<td colSpan={2}>
										<Sparklines data={row.trend}>
											<SparklinesLine
												style={{
													fill: 'none',
													strokeWidth: 3,
												}}
											/>

											<SparklinesReferenceLine type="avg" />
											<SparklinesSpots
												size={5}
												style={{
													stroke: '#336aff',
													strokeWidth: 3,
													fill: 'white',
												}}
											/>
										</Sparklines>
									</td>
								</tr>
								<tr>
									<th className="text-right p-2">Instrument</th>
									<td className="text-left p-2">{row.originId}</td>
								</tr>
								<tr>
									<th className="text-right p-2">Spread</th>
									<td className="text-left p-2">{row.spread}%</td>
								</tr>
								<tr>
									<th className="text-right p-2">30d avg.</th>
									<td className="text-left p-2">
										{row.spreadAvg ? `${row.spreadAvg}%` : `-`}
									</td>
								</tr>
								<tr>
									<th className="text-right p-2">Fee</th>
									<td className="text-left p-2">
										{row.fee ? `${row.fee}%` : `-`}
									</td>
								</tr>
								<tr>
									<th className="text-right p-2">Bid</th>
									<td className="text-left p-2">{row.bid ? row.bid : '-'}</td>
								</tr>
								<tr>
									<th className="text-right p-2">Ask</th>
									<td className="text-left p-2">{row.ask ? row.ask : '-'}</td>
								</tr>
								<tr>
									<th className="text-right p-2  text-xs">Updated</th>
									<td className="text-left p-2 text-xs">{row.updated}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ReactTooltip>
			</div>
		</td>
	);
};

export { SpreadCell };
