import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncSelect from 'react-select/async';
import { SelectOption } from '@types';
import { useEffect, useState } from 'react';

export interface SelectInstrumentsProps {
	onChange: (...args: any[]) => void;
	loadOptions: (inputValue: string) => Promise<SelectOption[]>;
}

const SelectInstruments = ({
	onChange,
	loadOptions,
}: SelectInstrumentsProps) => {
	const [screenWidth, getDimension] = useState(window.innerWidth);
	const setDimension = () => {
		getDimension(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', setDimension);

		return () => {
			window.removeEventListener('resize', setDimension);
		};
	}, [screenWidth]);
	return (
		<div className="relative">
			<div className="absolute top-0 bottom-0 left-0 flex items-center px-3 z-30">
				<div className="my-1 text-gray-500">
					<FontAwesomeIcon className="w-3 h-3 inline-block" icon={faPlus} />
				</div>
			</div>
			<AsyncSelect
				cacheOptions
				classNamePrefix="react-select"
				onChange={(el: any) => onChange(el?.value)}
				className="react-select-container"
				placeholder={screenWidth < 768 ? 'Add...' : 'Add Instruments'}
				loadOptions={loadOptions}
				noOptionsMessage={() => 'Start typing...'}
				menuShouldScrollIntoView
				styles={{
					indicatorsContainer: (base) => ({
						...base,
						display: 'none',
					}),
					option: (base, state) => ({
						...base,
						background: state.isFocused ? 'rgb(31 41 55)' : 'rgb(17 24 39)',
					}),
				}}
			/>
		</div>
	);
};

export { SelectInstruments };
