import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { parseCookies, setCookie } from 'nookies';
import { IRequestData, ISpreadFilter, ISpreadIndex, ShowType } from '@types';
import { NextRouter, useRouter } from 'next/router';

export const { NEXT_PUBLIC_API_URL } = process.env;

export const cookieFilterName = 'tsUserFilters';

export const theCountries = ['BS', 'GM', 'MV', 'NL', 'AE', 'GB', 'US', 'ZM'];

export const cookieOptions = {
	path: '/',
	maxAge: 365 * 24 * 60 * 60,
};

export interface IDataType {
	id: ShowType;
	name: string;
}
export const dataTypes: IDataType[] = [
	{
		id: 'ask',
		name: 'Ask',
	},
	{
		id: 'bid',
		name: 'Bid',
	},
	{
		id: 'spread',
		name: 'Current spreads',
	},
	{
		id: 'spreadAvg',
		name: 'Avg. spreads',
	},
	{
		id: 'fee',
		name: 'Fees',
	},
];

export const saveFilterCookie = (requestData: IRequestData) => {
	requestData.brokers = [];
	setCookie(
		null,
		requestData.type === 'crypto'
			? `${requestData.type}${cookieFilterName}`
			: cookieFilterName,
		JSON.stringify(requestData),
		cookieOptions
	);
};

export const composeSlug = (
	requestData: IRequestData,
	router: NextRouter,
	baseSlug: string
) => {
	const slugs = [];
	if (baseSlug === 'home') {
		baseSlug = '#';
	}
	const brokers = requestData.brokers.join(',');
	if (brokers) {
		slugs.push(`brokers:${brokers}`);
	}

	const instruments = requestData.markets.join(',');
	if (instruments) {
		slugs.push(`instruments:${instruments}`);
	}
	/*
	const countries = requestData.countries.join(',');
	if (countries) {
		slugs.push(`countries:${countries}`);
	}
  */
	if (slugs.length) {
		router.push(`/${baseSlug}/${slugs.join('/')}`, undefined, {
			shallow: true,
		});
	}
};

const parseSlug = (router: NextRouter) => {
	const slug =
		(router.query.slug as string[]) ||
		(router.asPath.replace('/#/', '').split('/') as string[]) ||
		[];
	const data: IRequestData = {
		markets: [],
		countries: [],
		brokers: [],
	};
	//console.log('slug', slug);
	slug.forEach((s) => {
		if (s.includes('-vs-')) {
			data.brokers.push(...s.split('-vs-'));
		}
		if (s.includes('brokers:')) {
			data.brokers.push(...s.replace('brokers:', '').split(','));
		}
		if (s.includes('countries:')) {
			data.countries.push(...s.replace('countries:', '').split(','));
		}
		if (s.includes('type:')) {
			//const type = s.replace('type:', '');
			//setShowType();
		}
		if (s.includes('instruments:')) {
			data.markets.push(
				...s
					.replace('instruments:', '')
					.split(',')
					.map((i) => {
						return parseInt(i);
					})
			);
		}
	});
	//console.log('requestData from route', data);
	if (
		slug.length === 1 &&
		data.brokers.length === 0 &&
		data.markets.length === 0
	) {
		data.brokers.push(slug[0]);
	}
	return data;
};

export const useFetchCompare = (
	requestData: IRequestData
	//	router: NextRouter | void
) => {
	const router = useRouter();
	const cookies = parseCookies();
	const cookieName =
		requestData.type === 'crypto'
			? `${requestData.type}${cookieFilterName}`
			: cookieFilterName;
	const cookieRequest = cookies[cookieName]
		? (JSON.parse(cookies[cookieName]) as IRequestData)
		: ({} as IRequestData);
	const routeRequest = router ? parseSlug(router) : ({} as IRequestData);
	//console.log('useFetchCompare', requestData, routeRequest, cookieRequest);

	requestData.markets =
		routeRequest.markets && routeRequest.markets.length
			? routeRequest.markets
			: cookieRequest.markets && cookieRequest.markets.length
			? cookieRequest.markets
			: requestData.markets;

	requestData.brokers =
		routeRequest.brokers && routeRequest.brokers.length
			? routeRequest.brokers
			: cookieRequest.brokers && cookieRequest.brokers.length
			? cookieRequest.brokers
			: requestData.brokers;
	/*console.log(
		'routeRequest',
		routeRequest.brokers,
		'cookieRequest',
		cookieRequest.brokers,
		'requestData',
		requestData.brokers
	);*/
	requestData.countries =
		routeRequest.countries && routeRequest.countries.length
			? routeRequest.countries
			: cookieRequest.countries && cookieRequest.countries.length
			? cookieRequest.countries
			: requestData.countries;

	const { data, error } = useSWR(
		[
			`${NEXT_PUBLIC_API_URL}compare`,
			{
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestData),
			},
		],
		null,
		{}
	);
	return {
		defaultSpreadFilter: data as ISpreadFilter,
		isLoading: !error && !data,
		isError: error,
		//mutateCompare: mutate,
	};
};

export const useFetchIndex = (type: string | void) => {
	const indexType = type ? `${type}/` : '';
	const { data, error } = useSWRImmutable(
		`${NEXT_PUBLIC_API_URL}${indexType}index`
	);
	return {
		defaultSpreadIndex: data as ISpreadIndex,
		isLoading: !error && !data,
		isError: error,
	};
};
