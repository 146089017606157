import { IInstrument } from '@types';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export interface InstrumentTdProps {
	market: IInstrument;
	onClick: (id: IInstrument['id']) => void;
}

const InstrumentTd = ({ market, onClick }: InstrumentTdProps) => {
	return (
		<td className="py-3 px-1 md:px-3 sticky left-0 z-10 bg-grey-200 dark:bg-off-black group-hover:bg-gray-200 dark:group-hover:bg-gray-800">
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<div className="w-10 h-10 flex-shrink-0 mr-1 sm:mr-2">
						<Image
							className="rounded-full"
							src={market.icon}
							width="30"
							height="30"
							alt=""
						/>
					</div>
					<div className="pr-2">
						<div className="mb-1">
							<Link
								href={`/fees-calculator/${market.symbol.toLocaleLowerCase()}`}
								passHref
							>
								<a className="underlined text-lg font-bold relative border-b-2 border-grey-300 dark:border-grey-700 hover:border-b-0">
									{market.symbol}
								</a>
							</Link>
						</div>
						<div className="text-xs w-20 md:w-auto overflow-hidden text-ellipsis max-w-xs">
							{market.name}
						</div>
					</div>
				</div>
				<div className="mt-3 lg:mt-0 w-7">
					<div
						onClick={() => onClick(market.id)}
						className=" bg-gray-200 py-2 dark:bg-gray-700 rounded-xl px-2 cursor-pointer opacity-70 hover:opacity-100"
					>
						<span>
							<FontAwesomeIcon className="w-3 h-3" icon={faClose} />
						</span>
					</div>
				</div>
			</div>
		</td>
	);
};

export { InstrumentTd };
