import { IBroker } from '@types';
import Link from 'next/link';
import Image from 'next/image';

export interface BrokerTdProps {
	broker: IBroker;
	baseSlug: string;
	removeBroker: (id: IBroker['id']) => void;
}

const BrokerTd = ({ broker, baseSlug, removeBroker }: BrokerTdProps) => {
	return (
		<td className="py-3 px-1 md:px-3 sticky left-0 z-10 bg-grey-200 dark:bg-off-black group-hover:bg-gray-200 dark:group-hover:bg-gray-800">
			<div className="lg:flex items-center justify-between">
				<div className="flex items-center">
					<div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
						<Image
							className="rounded-full"
							src={broker.logo}
							width="40"
							height="40"
							alt=""
						/>
					</div>
					<div className="pr-3">
						<Link
							href={`/${baseSlug === 'crypto' ? 'crypto' : 'compare-brokers'}/${
								broker.id
							}`}
							passHref
						>
							<a className="underlined font-bold relative border-b-2 border-grey-300 dark:border-grey-700 hover:border-b-0">
								{broker.name}
							</a>
						</Link>
					</div>
				</div>
				<a
					href={broker.signup}
					target="_blank"
					rel="nofollow noreferrer"
					className="mt-3 lg:mt-0 text-sm text-center block gradient-border transition transition-300 rounded-md px-4 py-2 headline hover:gradient-bg hover:text-off-white group whitespace-nowrap"
				>
					<span className="block headline group-hover:text-off-white">
						Visit Broker
					</span>
				</a>
			</div>
		</td>
	);
};

export { BrokerTd };
