import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { IBroker } from '@types';

export interface VersusItemProps {
	brokerOne: IBroker;
	brokerTwo: IBroker;
	path?: string;
}

const VersusItem = ({ brokerOne, brokerTwo, path }: VersusItemProps) => {
	return (
		<Link
			href={`/${path === 'crypto' ? path : 'compare-brokers'}/${
				brokerOne.id
			}-vs-${brokerTwo.id}`}
			shallow={path ? false : true}
			scroll={true}
			passHref
		>
			<a className="group break-inside hover:font-bold flex items-center hover:text-slate-100 hover:dark:text-inherit justify-between bg-gray-300 dark:bg-gray-800 hover:bg-gradient-to-r from-[#ec00d8] to-[#A649EE] rounded-xl p-4 mb-4">
				<div className="flex justify-between w-full">
					<div className="flex flex-col items-start justify-center">
						<span className="no-underline block">
							{brokerOne.name}
							<span className="inline-flex items-center justify-center rounded-full mx-1 w-8 h-6 ">
								vs
							</span>
							{brokerTwo.name}
						</span>
					</div>
					<div className="no-underline dark:text-slate-100 rounded-full px-3 py-3 transition-all group-hover:bg-slate-100 group-hover:text-slate-800">
						<FontAwesomeIcon className="w-4 h-4" icon={faArrowRight} />
					</div>
				</div>
			</a>
		</Link>
	);
};

export { VersusItem };
