const PlaceholderIndex = () => {
	return (
		<>
			<div className="animate-pulse lg:flex justify-between mt-8 mb-14">
				<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2"></div>
				<div className="nav nav-pills flex flex-row list-none pl-0 lg:justify-end gap-4">
					<div className="py-2 nav-item w-32 h-2 bg-slate-700 rounded"></div>
					<div className="py-2 nav-item w-32 h-2 bg-slate-700 rounded"></div>
				</div>
			</div>
			<div className="overflow-x-auto overflow-y-clip animate-pulse">
				<table className="overflow-y-visible table w-full divide-y-2 divide-gray-300 dark:divide-gray-800 whitespace-nowrap">
					<thead className="text-xs font-semibold">
						<tr>
							<th className="px-3 py-6 w-1/4  z-10 top-0">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-full"></div>
							</th>
							<th className="p-3 w-1/12 border-x border-gray-200 dark:border-gray-800  ">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2 mx-auto"></div>
							</th>
							<th className="p-3 w-1/12 border-x border-gray-200 dark:border-gray-800  ">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2 mx-auto"></div>
							</th>
							<th className="p-3 w-1/12 border-x border-gray-200 dark:border-gray-800  ">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2 mx-auto"></div>
							</th>
							<th className="p-3 w-1/12 border-x border-gray-200 dark:border-gray-800  ">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2 mx-auto"></div>
							</th>
							<th className="p-3 w-1/12 border-x border-gray-200 dark:border-gray-800  ">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/2 mx-auto"></div>
							</th>
						</tr>
					</thead>
					<tbody className="divide-y-2 divide-gray-300 dark:divide-gray-800">
						<tr className="hover:bg-gray-200 dark:hover:bg-gray-800">
							<td className="px-3 py-4">
								<div className="flex items-center">
									<div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
										<div className="rounded-full bg-slate-700 h-10 w-10"></div>
									</div>
									<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-2/3"></div>
								</div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
						</tr>

						<tr className="hover:bg-gray-200 dark:hover:bg-gray-800">
							<td className="px-3 py-4">
								<div className="flex items-center">
									<div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
										<div className="rounded-full bg-slate-700 h-10 w-10"></div>
									</div>
									<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-2/3"></div>
								</div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
						</tr>

						<tr className="hover:bg-gray-200 dark:hover:bg-gray-800">
							<td className="px-3 py-4">
								<div className="flex items-center">
									<div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
										<div className="rounded-full bg-slate-700 h-10 w-10"></div>
									</div>
									<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-2/3"></div>
								</div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
						</tr>

						<tr className="hover:bg-gray-200 dark:hover:bg-gray-800">
							<td className="px-3 py-4">
								<div className="flex items-center">
									<div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
										<div className="rounded-full bg-slate-700 h-10 w-10"></div>
									</div>
									<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-2/3"></div>
								</div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
							<td className="p-3 border-x border-gray-200 dark:border-gray-800 text-center">
								<div className="py-2 mb-4 lg:mb-0 h-2 bg-slate-700 rounded w-1/4 mx-auto"></div>
							</td>
						</tr>
						<tr className=" h-10">
							<td className="p-3 hidden"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export { PlaceholderIndex };
