import { SpreadCell } from 'SpreadCell';
import {
	IRequestData,
	ISpreadFilter,
	ISpreadIndex,
	SelectOption,
	ShowType,
} from '@types';
import { BrokerTh } from 'BrokerTh';
import { InstrumentTd } from 'InstrumentTd';
import { SelectInstruments } from 'SelectInstruments';
import { EmptyTd } from 'EmptyTd';
import React, { useState } from 'react';
import { composeSlug, saveFilterCookie } from 'utils/core';
import { useRouter } from 'next/router';
import { InstrumentTh } from 'InstrumentTh';
import { BrokerTd } from 'BrokerTd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';

export interface SpreadTableProps {
	spreadFilter: ISpreadFilter;
	spreadIndex: ISpreadIndex;
	baseSlug: string;
	showType: ShowType;
	instrumentsOnTop: boolean;
	setInstrumentsOnTop: (state: boolean) => void;
	setRequestData: (requestData: IRequestData) => void;
	setSpreadFilter: (spreadFilter: ISpreadFilter) => void;
}

const SpreadTable = ({
	spreadFilter,
	spreadIndex,
	baseSlug,
	showType,
	instrumentsOnTop,
	setInstrumentsOnTop,
	setRequestData,
	setSpreadFilter,
}: SpreadTableProps) => {
	const router = useRouter();
	const [status, setStatus] = useState<string>('');
	/*
	useEffect(() => {
		if (
			spreadFilter.filters.markets.length >= spreadFilter.filters.brokers.length
		) {
			setInstrumentsOnTop(false);
		}
	}, [spreadFilter.filters]);*/

	const removeBroker = (brokerId: string) => {
		console.log(1, spreadFilter.filters.brokers);
		spreadFilter.filters.brokers = spreadFilter.filters.brokers.filter(
			(row) => row.id != brokerId
		);
		const request: IRequestData = {
			markets: [],
			countries: [],
			brokers: [],
		};
		spreadFilter.filters.markets.map((row) => {
			request.markets.push(row.id);
		});
		spreadFilter.filters.countries.map((row) => {
			request.countries.push(row.id);
		});
		spreadFilter.filters.brokers.map((row) => {
			request.brokers.push(row.id);
		});
		setSpreadFilter(spreadFilter);
		setRequestData(request);
		composeSlug(request, router, baseSlug);
		setStatus(brokerId + ' removed');
	};

	const removeInstrument = (marketId: number) => {
		spreadFilter.filters.markets = spreadFilter.filters.markets.filter(
			(row) => row.id != marketId
		);
		const request: IRequestData = {
			markets: [],
			countries: [],
			brokers: [],
		};
		spreadFilter.filters.markets.map((row) => {
			request.markets.push(row.id);
		});
		spreadFilter.filters.countries.map((row) => {
			request.countries.push(row.id);
		});
		spreadFilter.filters.brokers.map((row) => {
			request.brokers.push(row.id);
		});
		saveFilterCookie({ ...request, type: baseSlug });
		setSpreadFilter(spreadFilter);
		setRequestData(request);
		composeSlug(request, router, baseSlug);
		setStatus(marketId + ' removed');
	};

	const addInstrument = (value: void | any) => {
		const requestData: IRequestData = {
			markets: [value],
			countries: [],
			brokers: [],
		};
		spreadFilter.filters.markets.map((row) => {
			requestData.markets.push(row.id);
		});
		spreadFilter.filters.countries.map((row) => {
			requestData.countries.push(row.id);
		});
		spreadFilter.filters.brokers.map((row) => {
			requestData.brokers.push(row.id);
		});
		composeSlug(requestData, router, baseSlug);
		saveFilterCookie({ ...requestData, type: baseSlug });
		setRequestData(requestData);
	};

	const orderByInstrument = (marketId: number) => {
		const brokerIds = spreadFilter.results
			.filter((result) => result.marketId == marketId)
			.sort((a, b) => {
				return a[showType] > b[showType] ? 1 : -1;
			})
			.map((filter) => {
				return filter.brokerId;
			})
			.filter((v, i, a) => a.indexOf(v) === i);

		const brokers = spreadFilter.filters.brokers.sort((a, b) => {
			const index1 = brokerIds.indexOf(a.id);
			const index2 = brokerIds.indexOf(b.id);
			return (
				(index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity)
			);
		});

		spreadFilter.filters.brokers = brokers;
		setSpreadFilter(spreadFilter);
		setStatus(marketId + ' sorted');
	};

	const searchInstruments = (inputValue: string) => {
		if (inputValue.length <= 1) {
			return [];
		}
		return spreadIndex.markets.filter((i) =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const promiseInstruments = (inputValue: string) =>
		new Promise<SelectOption[]>((resolve) => {
			resolve(searchInstruments(inputValue));
		});

	const fetchVersus = async (brokerOne: string, brokerTwo?: string) => {
		const requestData: IRequestData = {
			markets: [],
			countries: [],
			brokers: [brokerOne],
		};
		if (brokerTwo) {
			requestData.brokers.push(brokerTwo);
		}
		spreadFilter.filters.markets.map((row) => {
			requestData.markets.push(row.id);
		});
		setInstrumentsOnTop(false);
		setRequestData(requestData);
	};
	return (
		<div className="overflow-x-auto overflow-y-clip">
			<table className="overflow-y-visible table w-full divide-y-2 divide-gray-300 dark:divide-gray-800 whitespace-nowrap">
				<thead className="text-xs font-semibold">
					<tr key="search-market">
						<th className="py-3 px-1 md:px-3 top-0 sticky z-20 left-0 font-normal w-1/12 align-bottom bg-grey-200 dark:bg-off-black">
							<SelectInstruments
								onChange={addInstrument}
								loadOptions={promiseInstruments}
							/>
						</th>
						{instrumentsOnTop ? (
							<InstrumentTh
								markets={spreadFilter.filters.markets}
								removeInstrument={removeInstrument}
								orderByInstrument={orderByInstrument}
							/>
						) : (
							<BrokerTh
								brokers={spreadFilter.filters.brokers}
								baseSlug={baseSlug}
								removeBroker={removeBroker}
								fetchVersus={fetchVersus}
							/>
						)}
					</tr>
				</thead>
				<tbody className="divide-y-2 divide-gray-300 dark:divide-gray-800 ">
					{!instrumentsOnTop &&
						spreadFilter.filters.markets.map((market) => {
							return (
								<tr
									className="hover:bg-gray-200 dark:hover:bg-gray-800 group"
									key={market.id}
								>
									<InstrumentTd market={market} onClick={removeInstrument} />

									{spreadFilter.filters.brokers.map((broker) => {
										if (
											spreadFilter.results.filter(
												(row) =>
													row.brokerId === broker.id &&
													row.marketId === market.id
											).length >= 1
										) {
											return (
												<React.Fragment key={`${broker.id}-${market.id}`}>
													{spreadFilter.results
														.filter(
															(row) =>
																row.brokerId === broker.id &&
																row.marketId === market.id
														)
														.slice(0, 1)
														.map((row) => {
															return (
																<SpreadCell
																	row={row}
																	showType={showType}
																	key={row.id}
																/>
															);
														})}
												</React.Fragment>
											);
										} else {
											return <EmptyTd key={`${broker.id}-${market.id}`} />;
										}
									})}
								</tr>
							);
						})}

					{instrumentsOnTop &&
						spreadFilter.filters.brokers.map((broker) => {
							return (
								<tr
									className="hover:bg-gray-200 dark:hover:bg-gray-800 group"
									key={broker.id}
								>
									<BrokerTd
										broker={broker}
										removeBroker={removeBroker}
										baseSlug={baseSlug}
									/>

									{spreadFilter.filters.markets.map((market) => {
										if (
											spreadFilter.results.filter(
												(row) =>
													row.brokerId === broker.id &&
													row.marketId === market.id
											).length >= 1
										) {
											return (
												<React.Fragment key={`${broker.id}-${market.id}`}>
													{spreadFilter.results
														.filter(
															(row) =>
																row.brokerId === broker.id &&
																row.marketId === market.id
														)
														.slice(0, 1)
														.map((row) => {
															return (
																<SpreadCell
																	row={row}
																	showType={showType}
																	key={row.id}
																/>
															);
														})}
												</React.Fragment>
											);
										} else {
											return <EmptyTd key={`${broker.id}-${market.id}`} />;
										}
									})}
								</tr>
							);
						})}

					<tr className="text-left text-sm">
						<td className="p-4 sticky left-0 z-10">
							<span
								className="hover:opacity-100 opacity-70 cursor-pointer"
								onClick={() => setInstrumentsOnTop(!instrumentsOnTop)}
							>
								<FontAwesomeIcon
									className="h-4 w-4 inline-block"
									icon={faRetweet}
								/>{' '}
								Transpose data
							</span>{' '}
							<span className="hidden">{status}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export { SpreadTable };
