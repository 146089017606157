import Head from 'next/head';

export interface HeadProps {
	title: string;
	description?: string;
	lang?: string;
	keywords?: string;
	author?: string;
	image?: string;
	canonical?: string;
	noindex?: string;
	meta?: Array<{
		name: string;
		content: string;
	}>;
}

const SeoHead = ({
	title,
	description,
	author = 'TradingSpreads',
	keywords,
	noindex,
	canonical,
	image = 'https://cdn.tradingspreads.io/i/tradingspreads-preview-homepage.webp',
	meta,
}: HeadProps) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description ?? ''} />
			{keywords && <meta name="keywords" content={keywords} />}
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			{canonical && <link href={canonical} rel="canonical" />}
			{noindex === '1' ? <meta name="robots" content="noindex,nofollow" /> : ''}
			<meta name="author" content={author} />
			<meta name="og:title" content={title} />
			<meta name="og:description" content={description ?? ''} />
			<meta name="og:type" content="website" />
			<meta name="og:url" content="https://tradingspreads.io/" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description ?? ''} />
			<meta name="twitter:creator" content={author} />
			{meta &&
				meta.length > 0 &&
				meta.map(({ name, content }) => (
					<meta name={name} content={content} key={name} />
				))}
			{image && <meta name="og:image" content={image} />}
			{image && <meta name="twitter:image" content={image} />}
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{"@context":"http://schema.org","@type":"WebSite","name":"TradingSpreads","alternateName":"Trading Spreads","url":"https://tradingspreads.io/","description":"","image":"${image}"}`,
				}}
			/>
		</Head>
	);
};

export { SeoHead };
