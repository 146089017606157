import { IInstrument } from '@types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSort } from '@fortawesome/free-solid-svg-icons';

export interface InstrumentThProps {
	markets: IInstrument[];
	removeInstrument: (id: IInstrument['id']) => void;
	orderByInstrument: (id: IInstrument['id']) => void;
}

const InstrumentTh = ({
	markets,
	removeInstrument,
	orderByInstrument,
}: InstrumentThProps) => {
	return (
		<>
			{markets.map((row) => {
				return (
					<th
						className="sticky z-10 top-0 py-3 px-1 md:px-3 border-x border-gray-200 dark:border-gray-800 w-1/12"
						key={row.id}
					>
						<div className="font-semibold text-center flex flex-nowrap  justify-center">
							<div
								onClick={() => removeInstrument(row.id)}
								className="bg-gray-200 dark:bg-gray-700 rounded-lg px-2 py-1 cursor-pointer opacity-70 hover:opacity-100"
							>
								{row.symbol}
								<span className="py-1 font-normal ml-2 ">
									<FontAwesomeIcon
										className="w-3 h-3 inline-block"
										icon={faClose}
									/>
								</span>
							</div>
							<div
								onClick={() => orderByInstrument(row.id)}
								className="py-1 pl-2 px-1 md:pl-3 md:px-0 font-normal  cursor-pointer opacity-60 hover:opacity-100"
							>
								<FontAwesomeIcon
									className="w-3 h-3 inline-block"
									icon={faSort}
								/>
							</div>
						</div>
					</th>
				);
			})}
		</>
	);
};

export { InstrumentTh };
