import { IBroker } from '@types';
import { VersusItem } from 'VersusItem';

export interface VersusListProps {
	allBrokers: IBroker[];
	filteredBrokers: IBroker[];
	randomBrokerIndex: number;
	baseSlug: string;
}

const VersusList = ({
	allBrokers,
	filteredBrokers,
	randomBrokerIndex,
	baseSlug,
}: VersusListProps) => {
	return (
		<div>
			<h2 className="headline my-12 text-3xl md:text-4xl lg:text-4xl">
				{baseSlug === 'crypto' ? `Exchanges` : `Brokers`} Head to Head
			</h2>

			<div className="box-border max-w-full sm:columns-3 md:columns-3 lg:columns-3 xl:columns-4 gap-4 ">
				{allBrokers
					.filter(
						(broker) =>
							typeof filteredBrokers[randomBrokerIndex] !== 'undefined' &&
							filteredBrokers.length >= randomBrokerIndex + 1 &&
							broker.id !== filteredBrokers[randomBrokerIndex].id
					)
					.sort(() => {
						return 0.5 - Math.random();
					})
					.slice(0, 4)
					.map((broker) => {
						return (
							<VersusItem
								key={`${broker.id}-vs-${randomBrokerIndex}`}
								brokerOne={filteredBrokers[randomBrokerIndex]}
								brokerTwo={broker}
								path={baseSlug}
							/>
						);
					})}
			</div>
		</div>
	);
};

export { VersusList };
