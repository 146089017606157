import { IBroker } from '@types';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export interface BrokerThProps {
	brokers: IBroker[];
	baseSlug: string;
	removeBroker: (id: string) => void;
	fetchVersus: (id: IBroker['id']) => void;
}

const BrokerTh = ({
	brokers,
	baseSlug,
	removeBroker,
	fetchVersus,
}: BrokerThProps) => {
	return (
		<>
			{brokers.map((broker) => {
				return (
					<th
						className="sticky z-10 top-0 py-3 px-1 md:px-3 group border-x border-gray-200 dark:border-gray-800 w-1/6"
						key={broker.id}
					>
						<div className="block relative">
							<div className="absolute top-0 right-0 w-7 h-7">
								<div className="w-7">
									<div
										onClick={() => removeBroker(broker.id)}
										className="text-sm bg-gray-200 dark:bg-gray-700 rounded-xl py-2 px-2 cursor-pointer opacity-70 hover:opacity-100 block lg:hidden group-hover:block"
									>
										<span>
											<FontAwesomeIcon className="w-3 h-3" icon={faClose} />
										</span>
									</div>
								</div>
							</div>
							<div className="block">
								<div className="w-10 h-10 mx-auto mb-2">
									<Image
										className="rounded-full"
										src={broker.logo}
										width="40"
										height="40"
										alt=""
									/>
								</div>
								<div className="mx-auto mb-3">
									<Link href={`/${baseSlug}/${broker.id}`} passHref>
										<a
											className="underlined text-lg font-bold relative border-b-2 border-grey-300 dark:border-grey-700 hover:border-b-0"
											onClick={() => fetchVersus(broker.id)}
										>
											{broker.name}
										</a>
									</Link>
								</div>
								<div className="mx-auto">
									<a
										href={broker.signup}
										target="_blank"
										rel="nofollow noreferrer"
										className=" max-w-fit w-full xl:w-40 mx-auto mt-3 lg:mt-0 text-center block gradient-border transition transition-300 rounded-md px-4 py-2 headline hover:gradient-bg hover:text-off-white group whitespace-nowrap"
									>
										<span className="block headline uppercase group-hover:text-off-white">
											Visit Broker
										</span>
									</a>
								</div>
							</div>
						</div>
					</th>
				);
			})}
		</>
	);
};

export { BrokerTh };
