import { FC, ReactNode } from 'react';

interface ContainerProps {
	children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => {
	return <div className="mx-auto w-full px-2 md:px-4 xl:px-20">{children}</div>;
};

export { Container };
