export interface ShowTypeItemProps {
	id: string;
	name: string;
	activeId: string;
	onClick: (...args: unknown[]) => void;
}

const ShowTypeItem = ({ id, name, activeId, onClick }: ShowTypeItemProps) => {
	const defaultClassNames = `nav-link block text-sm rounded-xl px-5 py-2 md:mx-2 focus:outline-none focus:ring-0`;
	return (
		<li className="nav-item">
			<button
				onClick={onClick}
				className={
					activeId === id
						? `${defaultClassNames} bg-gray-200 dark:bg-gray-800 font-semibold`
						: `${defaultClassNames} hover:bg-gray-200 dark:hover:bg-gray-800`
				}
			>
				{name}
			</button>
		</li>
	);
};

export { ShowTypeItem };
